<template>
  <!-- 新增设备 -->
  <el-dialog :title="title" :visible.sync="deviceVisible" width="650px" :before-close="handleClose"
    :close-on-click-modal="false" center :modal-append-to-body='false'>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
        <el-form-item label="设备类型" prop="category" style="width: 100%;">
          <el-select v-model="ruleForm.category" placeholder="请选择" style="width: 100%;" @change="getModelList">
            <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="设备型号" prop="model" style="width: 100%;">
          <el-select v-model="ruleForm.model" placeholder="请选择" style="width: 100%;">
            <el-option v-for="item in modelList" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="生产厂商" prop="manufacturer" style="width: 100%;">
          <el-select v-model="ruleForm.manufacturer" placeholder="请选择 " style="width: 100%;">
            <el-option v-for="item in manufacturerList" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="显示排序" prop="position">
          <el-input v-model="ruleForm.position" />
        </el-form-item>
        <el-form-item label="安装位置" prop="place">
          <el-cascader :options="placeList" :props="defaultProps" v-model="ruleForm.place" style="width:100%;" clearable ref="catalogueExport" @change="handleExportCatalogChange" />
        </el-form-item>
        <el-form-item label="通讯协议" style="width:100%;" v-if="ruleForm.stype=='30'">
           <el-select v-model="ruleForm.ptype" style="width:100%">
              <el-option label="RTU通讯" value="RTU" />
              <el-option label="TCP通讯" value="TCP" />
           </el-select>
        </el-form-item>
        <el-form-item label="IP地址" prop="ip" v-if="ruleForm.ptype=='TCP'">
          <el-input v-model="ruleForm.ip" />
        </el-form-item>
        <el-form-item label="从机地址" prop="address">
          <el-input v-model="ruleForm.address" />
        </el-form-item>
        <el-form-item label="通讯端口" prop="port" v-if="ruleForm.stype=='30'">
          <el-input v-model="ruleForm.port" />
        </el-form-item>
        <el-form-item label="设备简称" prop="productNick">
          <el-input v-model="ruleForm.productNick" />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      modelList: [],
      manufacturerList: [],
      categoryList: [],
      placeList: [],
      ruleForm: {
        position: 99,
        stype:10,
        ptype:"",
        address:"",
        port:"",
        ip:""
      },
      defaultProps: {
        children: "nodeList",
        label: "name",
        value: "id",
        checkStrictly: true
      },
      title: "新增设备",
      rules: {
        category: [
          { required: true, message: "请选择设备类型", trigger: "change" },
        ],
        model: [
          { required: true, message: "请选择设备型号", trigger: "change" },
        ],
        manufacturer: [
          { required: true, message: "请选择制造商", trigger: "change" },
        ],
        position: [
          { required: true, message: "请设置显示顺序", trigger: "change" },
        ],
        place: [
          { required: true, message: "请选择安装位置", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
    ...mapState(["projectCurrentRowData"])
  },
  mounted() {
    
  },
  watch: {
    deviceVisible(newValue) {
      if (newValue) {
        if (this.projectCode != undefined) {
          this.selectList();
        }
        this.ruleForm.stype = this.currentDevice.stype;
        if (this.deviceAddState == 0) {
          this.title = "新增设备";
          this.ruleForm.ptype = "RTU";
        }
        if (this.deviceAddState == 1) {
          this.title = "编辑设备";
          this.ruleForm.id = this.currentDevice.id;
          this.ruleForm.category = this.currentDevice.category;
          this.ruleForm.gateway = this.currentDevice.gateway;
          this.ruleForm.manufacturer = this.currentDevice.manufacturer;
          this.ruleForm.model = this.currentDevice.model;
          this.ruleForm.position = this.currentDevice.position;
          this.ruleForm.productNick = this.currentDevice.productNick;
          this.ruleForm.project = this.projectCode;
          this.ruleForm.place = this.currentDevice.place;
          this.ruleForm.address = this.currentDevice.address;
          this.ruleForm.port = this.currentDevice.port;
          this.ruleForm.ip = this.currentDevice.ip;
          this.ruleForm.ptype = this.currentDevice.ptype;
          this.ruleForm.baudRate = this.currentDevice.baudRate;
          this.ruleForm.productNick = this.currentDevice.productNick;
          this.ruleForm = { ...this.ruleForm }
        }
      }
    },
  },
  props: ["deviceVisible", "deviceAddState", "currentDevice", "projectCode", "deviceObj"],
  methods: {
    handleExportCatalogChange(val) {
      this.$refs.catalogueExport.dropDownVisible = false
    },
    selectList() {
      this.request.categoryList().then((res) => {
        this.categoryList = res.data;
        if (this.deviceAddState == 0) {
          this.ruleForm.category = this.categoryList[0].id;
          this.getModelList(this.categoryList[0].id);
        } else if (this.deviceAddState == 1) {
          this.getModelList(this.ruleForm.category);
        }
      });
      this.request.manufacturerList().then((res) => {
        this.manufacturerList = res.data;
      });
      this.request.placeList({ project: this.projectCode }).then((res) => {
        this.placeList = [res.data];
      });
    },
    getModelList(val) {
      const params = {};
      params.category = val;
      this.request.modelList(params).then((res) => {
        this.modelList = res.data;
      });
    },
    onInput() {
      //input输入框无法编辑
      this.$forceUpdate();
    },
    handleClose() {
      this.ruleForm = {

      };
      this.$refs["ruleForm"].resetFields();
      this.$emit("deviceClose");
    },
    //提交
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.deviceAddState == 0) {
            this.ruleForm = Object.assign(this.deviceObj, this.ruleForm);
            this.request.deviceAdd(this.ruleForm).then((res) => {
              if (res.code == 0) {
                this.$message.success("新增成功");
                this.handleClose();
                this.$emit("getDeviceList");
              }
            });
          } else {
            this.request.deviceEdit(this.ruleForm).then((res) => {
              if (res.code == 0) {
                this.$message.success("编辑成功");
                this.handleClose();
                this.$emit("getDeviceList");
              }
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>