<template>
  <div class="scene-view">
    <div class="scene-container">
      <scene-detail ref="detail" @edit="getDetailInfo" />
      <div class="exp-title">&nbsp;</div>
      <el-form class="container" ref="form" :model="form" :show-message="false">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="box">
              <div class="box-label">场景名称</div>
              <div class="box-container">
                <div class="box-label-container">
                  <p class="group-title">
                    <el-form-item
                      prop="name"
                      :rules="[
                        {
                          type: 'string',
                          required: true,
                          message: '请填写场景名',
                          trigger: 'blur',
                        },
                      ]">
                      <el-input
                        size="mini"
                        placeholder="请给您的场景命名"
                        v-model.trim="form.name"
                      ></el-input>
                    </el-form-item>
                  </p>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="box">
              <div class="box-label">设定条件</div>
              <div class="box-container">
                <div class="box-scroll">
                  <div class="box-label-container" v-for="(condition, index) in conditions" :key="index">
                    <p class="blankDiv">
                      <span v-if="index >= 1">{{conditions.length > 1 ? "或" : ""}}</span>
                    </p>
                    <p class="value">
                      <el-form-item :prop="condition.key + '_deviceId'"
                        :rules="[
                          {
                            required: true,
                            message: '请选择设备',
                            trigger: 'change',
                          },
                        ]">
                        <el-select
                          size="mini"
                          placeholder="请选择设备"
                          style="width: 90%"
                          v-model="form[condition.key + '_deviceId']"
                          @change="changeReadConditionDevice(form[condition.key + '_deviceId'],index)">
                          <el-option
                            v-for="(item, index) in deviceList"
                            :key="'fd_device_' + index + '_' + item.id"
                            :label="item.categoryName"
                            :value="item.id"/>
                        </el-select>
                      </el-form-item>
                    </p>
                    <p class="value">
                      <el-form-item
                        :prop="condition.key + '_attr'"
                        :rules="[
                          {
                            required: true,
                            message: '请选择属性',
                            trigger: 'change',
                          },
                        ]">
                        <el-select
                          size="mini"
                          placeholder="请选择属性"
                          style="width: 90%"
                          v-model="form[condition.key + '_attr']">
                          <el-option
                            v-for="(item, index) in deviceReadConditionList[index]"
                            :key="condition.key + '_attr_' + index"
                            :label="item.name"
                            :value="item.id"/>
                        </el-select>
                      </el-form-item>
                    </p>
                    <p class="value">
                      <el-form-item
                        :prop="condition.key + '_cmp'"
                        :rules="[
                          {
                            required: true,
                            message: '请选择条件',
                            trigger: 'change',
                          },
                        ]">
                        <el-select
                          size="mini"
                          placeholder="请选择条件"
                          style="width: 90%"
                          v-model="form[condition.key + '_cmp']">
                          <el-option v-for="(item, index) in compareList" 
                            :key="condition.key + '_condition' + index + '_' + item.value"
                            :label="item.label"
                            :value="item.value"/>
                        </el-select>
                      </el-form-item>
                    </p>
                    <p class="value">
                      <el-form-item
                        :prop="condition.key + '_value'"
                        :rules="[
                          {
                            required: true,
                            message: '请设定数值',
                            trigger: 'change',
                          },
                        ]">
                        <el-input-number
                          size="mini"
                          placeholder="请设定数值"
                          style="width: 90%"
                          v-model="form[condition.key + '_value']"/>
                      </el-form-item>
                    </p>
                    <p @click="deleteCondition(index, condition)">
                      <i class="el-icon-delete"></i>
                    </p>
                  </div>
                </div>
                <div class="add-btn" @click="addCondition">+ 添加条件</div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="box">
              <div class="box-label">触发动作</div>
              <div class="box-container">
                <div class="box-scroll">
                  <div class="box-label-container" v-for="(action, index) in actions" :key="index">
                    <p class="value">
                      <el-form-item
                        :prop="action.key + '_deviceId'"
                        :rules="[
                          {
                            required: true,
                            message: '请选择设备',
                            trigger: 'change',
                          },
                        ]">
                        <el-select
                          size="mini"
                          placeholder="请选择设备"
                          style="width: 90%"
                          v-model="form[action.key + '_deviceId']"
                          @change="
                            changeWriteConditionDevice(
                              form[action.key + '_deviceId'],
                              index
                            )">
                          <el-option
                            v-for="(item, index) in deviceList"
                            :key="action.key + '_device_' + index + '_' + item.id"
                            :label="item.categoryName"
                            :value="item.id"/>
                        </el-select>
                      </el-form-item>
                    </p>
                    <p class="value">
                      <el-form-item
                        :prop="action.key + '_attr'"
                        :rules="[
                          {
                            required: true,
                            message: '请选择状态',
                            trigger: 'change',
                          },
                        ]">
                        <el-select
                          size="mini"
                          placeholder="请选择状态"
                          style="width: 90%"
                          v-model="form[action.key + '_attr']">
                          <el-option
                            v-for="(item, index) in deviceWriteConditionList[index]"
                            :key="action.key + '_attr_' + index + '_' + item.value"
                            :label="item.name"
                            :value="item.id"/>
                        </el-select>
                      </el-form-item>
                    </p>
                    <p class="value">
                      <el-form-item
                        :prop="action.key + '_value'"
                        :rules="[
                          {
                            required: true,
                            message: '请设定数值',
                            trigger: 'change',
                          },
                        ]">
                        <el-input-number
                          size="mini"
                          placeholder="请设定数值"
                          style="width: 90%"
                          v-model="form[action.key + '_value']"/>
                      </el-form-item>
                    </p>
                    <p @click="deleteAction(index, action)">
                      <i class="el-icon-delete"></i>
                    </p>
                  </div>
                </div>
                <div class="add-btn" @click="addAction">+ 添加结果</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div class="submit-btn">
        <div class="submit-btn-item" @click="submit">保存</div>
        <div class="submit-btn-item" @click="reset">重置</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SceneDetail from "./module/sceneDetail.vue";
import compareList from "./js/compare";
export default {
  name: "ProInfoScene",
  components: {
    SceneDetail
  },
  data() {
    return {
      form: {},
      conditions: [], // 渲染设定条件 html
      actions: [], // 渲染触发动作 html
      compareList,
      deviceList: [], // 设备列表
      deviceParamsList: [], // 根据设备获取设备参数
      deviceReadConditionList: [], // 用户可读参数
      deviceWriteConditionList: [], // 用户可读可写参数
    };
  },
  computed: {
    ...mapState(["projectCurrentRowData"]),
    setConditionAttrList() {
      return (index) => {
        return this.deviceReadConditionList[index] || [];
      };
    },
  },
  mounted() {
    this.reset();
    this.getDeviceList();
  },
  methods: {
    getDeviceList() {
      this.request.deviceList({ project: this.projectCurrentRowData.projectCode }).then((res) => {
        this.deviceList = res.data || [];
      });
    },
    getDeviceParams(deviceId, index, flag) {
      this.request.deviceScreenInfo({
        deviceId,
        project: this.projectCurrentRowData.projectCode,
      }).then((res) => {
        this.deviceParamsList = (res.data || {}).dictParams || [];
        // readWrite 10：可读，20：可写，30：可读可写
        if (flag === 1) {
          // 用户可读 10
          this.deviceReadConditionList[index] = this.deviceParamsList.filter(
            (item) => item.readWrite == 10
          );
          this.deviceReadConditionList.splice(0, 0);
        }
        if (flag === 2) {
          // 用户可读可写 20 30
          this.deviceWriteConditionList[index] = this.deviceParamsList.filter(
            (item) => item.readWrite == 20 || item.readWrite == 30
          );
          this.deviceWriteConditionList.splice(0, 0);
        }
      });
    },
    changeReadConditionDevice(e, index) {
      this.deviceReadConditionList[index] = [];
      this.getDeviceParams(e, index, 1);
    },
    changeWriteConditionDevice(e, index) {
      this.deviceWriteConditionList[index] = [];
      this.getDeviceParams(e, index, 2);
    },
    addCondition() {
      const key = "fd_" + new Date().getTime();
      this.conditions.push({
        deviceId: "",
        attr: "",
        cmp: "",
        value: "",
        key,
      });
      this.setFormValue(key + "_deviceId", "");
      this.setFormValue(key + "_attr", "");
      this.setFormValue(key + "_cmp", "");
      this.setFormValue(key + "_value", "");
    },
    addAction() {
      const key = "fe_" + new Date().getTime();
      this.actions.push({
        deviceId: "",
        attr: "",
        value: "",
        key,
      });
      this.setFormValue(key + "_deviceId", "");
      this.setFormValue(key + "_attr", "");
      this.setFormValue(key + "_value", "");
    },
    // 删除 当前行 设定条件
    deleteCondition(index, item) {
      this.conditions.splice(index, 1);
      const deviceName = item.key + "_deviceId";
      const attrName = item.key + "_attr";
      const compareName = item.key + "_cmp";
      const valueName = item.key + "_value";
      this.deleteFormValue(deviceName);
      this.deleteFormValue(attrName);
      this.deleteFormValue(compareName);
      this.deleteFormValue(valueName);
    },
    deleteAction(index, item) {
      this.actions.splice(index, 1);
      const key = item.key;
      const deviceName = key + "_deviceId";
      const attrName = key + "_attr";
      const valueName = key + "_value";
      this.deleteFormValue(deviceName);
      this.deleteFormValue(attrName);
      this.deleteFormValue(valueName);
    },
    // 动态设置 form 的某个属性和值
    setFormValue(keyName, value) {
      this.$set(this.form, keyName, value);
    },
    // 动态删除 form 的某个属性和值
    deleteFormValue(keyName) {
      this.$delete(this.form, keyName);
    },
    // 只读参数格式化中文
    fmtConditionAttrNickName(id) {
      for (let i = 0; i < this.deviceReadConditionList.length; i++) {
        const item = this.deviceReadConditionList[i] || [];
        for (let j = 0; j < item.length; j++) {
          if (item[j].id === id) {
            return item[j].name;
          }
        }
      }
    },
    // 可读写参数格式化中文名字
    fmtActionsAttrNickName(id) {
      for (let i = 0; i < this.deviceWriteConditionList.length; i++) {
        const item = this.deviceWriteConditionList[i] || [];
        for (let j = 0; j < item.length; j++) {
          if (item[j].id === id) {
            return item[j].name;
          }
        }
      }
    },
    // 格式化 触发动作数据
    fmtActions() {
      let obj = {};
      const formResult = this.form;
      const arr = Object.keys(formResult);
      arr.forEach((key) => {
        const value = formResult[key];
        if (key.indexOf("fe_") > -1) {
          obj[key] = value;
        }
      });
      let items = [];
      for (let i = 0; i < this.actions.length; i++) {
        const item = this.actions[i];
        const device = obj[item.key + "_deviceId"];
        const attr = obj[item.key + "_attr"];
        const value = obj[item.key + "_value"];
        items.push({
          deviceId: device,
          id: attr,
          attr: this.fmtActionsAttrNickName(attr),
          value: value,
        });
      }
      return items;
    },
    // 格式化 触发条件数据
    fmtConditions() {
      let obj = {};
      const formResult = this.form;
      const arr = Object.keys(formResult);
      arr.forEach((key) => {
        const value = formResult[key];
        if (key.indexOf("fd_") > -1) {
          obj[key] = value;
        }
      });
      let items = [];
      for (let i = 0; i < this.conditions.length; i++) {
        const item = this.conditions[i];
        const device = obj[item.key + "_deviceId"];
        const attr = obj[item.key + "_attr"];
        const cmp = obj[item.key + "_cmp"];
        const value = obj[item.key + "_value"];
        items.push({
          deviceId: device,
          id: attr,
          attr: this.fmtConditionAttrNickName(attr),
          cmp: cmp,
          value: value,
        });
      }
      return items;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const conditions = this.fmtConditions();
          const actions = this.fmtActions();
          if (this.form.id) {
            this.submitEdit(conditions, actions)
          } else {
            this.submitAdd(conditions, actions)
          }
        } else {
          this.$message.warning("场景条件必须填写");
          return false;
        }
      });
    },
    submitAdd(conditions, actions) {
      this.request.addRule({
        actions,
        conditions,
        ruleType: 10,
        name: this.form.name,
        project: this.projectCurrentRowData.projectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "添加成功",
          });
          this.reset();
          this.$refs.detail.getData();
        }
      });
    },
    submitEdit(conditions, actions) {
      this.request.editRule({
        actions,
        conditions,
        ruleType: 10,
        name: this.form.name,
        id: this.form.id,
        project: this.projectCurrentRowData.projectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "添加成功",
          });
          this.reset();
          this.$refs.detail.getData();
        }
      });
    },
    reset() {
      const key = new Date().getTime();
      this.form = {};
      this.setFormValue("name", "");
      this.setFormValue("fd_" + key + "_deviceId", "");
      this.setFormValue("fd_" + key + "_attr", "");
      this.setFormValue("fd_" + key + "_cmp", "");
      this.setFormValue("fd_" + key + "_value", "");
      this.setFormValue("fe_" + key + "_deviceId", "");
      this.setFormValue("fe_" + key + "_attr", "");
      this.setFormValue("fe_" + key + "_value", "");
      this.conditions = [
        {
          deviceId: "",
          attr: "",
          cmp: "",
          value: "",
          key: "fd_" + key,
        },
      ];
      this.actions = [
        {
          deviceId: "",
          attr: "",
          value: "",
          key: "fe_" + key,
        },
      ];
      this.deviceConditionList = [];
      this.$refs.form.resetFields();
    },
    // 编辑详情
    getDetailInfo(item) {
      this.request.infoRule({ id: item.id }).then((res) => {
        if (res.code === 0 && res.data && res.data.id) {
          this.form = JSON.parse(JSON.stringify(res.data));
          const conditions = JSON.parse(
            JSON.stringify(res.data.conditions || [])
          );
          for (let i = 0; i < conditions.length; i++) {
            const key = new Date().getTime();
            conditions[i]["key"] = "fd_" + key;
            const deviceId = conditions[i].deviceId;
            const attr = conditions[i].id;
            const cmp = conditions[i].cmp;
            const value = conditions[i].value;
            this.setFormValue("fd_" + key + "_deviceId", deviceId);
            this.setFormValue("fd_" + key + "_attr", attr*1);
            this.setFormValue("fd_" + key + "_cmp", cmp);
            this.setFormValue("fd_" + key + "_value", value);
            this.changeReadConditionDevice(deviceId, i);
          }
          const actions = JSON.parse(JSON.stringify(res.data.actions || []));
          for (let i = 0; i < actions.length; i++) {
            const key = new Date().getTime();
            actions[i]["key"] = "fe_" + key;
            const deviceId = actions[i].deviceId;
            const attr = actions[i].id;
            const value = actions[i].value;
            this.setFormValue("fe_" + key + "_deviceId", deviceId);
            this.setFormValue("fe_" + key + "_attr", attr*1);
            this.setFormValue("fe_" + key + "_value", value);
            this.changeWriteConditionDevice(deviceId, i);
          }
          this.conditions = conditions;
          this.actions = actions;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.scene-view {
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  .scene-header {
    height: 60px;
    line-height: 60px;
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 16px;
    color: #424e66;
    padding-left: 24px;
    border-bottom: 1px solid #d8d8d8;
    box-sizing: border-box;
  }

  .scene-container {
    height: calc(100% - 60px);
    padding: 12px;
    padding-top: 0;
    box-sizing: border-box;
    overflow-y: auto;
    .exp-title {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 16px;
      color: #424e66;
      margin-bottom: 18px;
      margin-top: 18px;
    }

    .container {
      margin: 18px 0;
      .el-form-item {
        margin-bottom: 0;
      }
    }

    .box-label {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 12px;
      color: #666666;
      margin-bottom: 8px;
    }

    .box-container {
      background: #f5f5f7;
      box-sizing: border-box;
      padding-top: 12px;
      padding-left: 12px;
      padding-bottom: 12px;
      height: 124px;
      .box-scroll {
        height: 72px;
        overflow-y: auto;
        padding-right: 12px;
        box-sizing: border-box;
      }
      .box-label-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .blankDiv {
          width: 25px;
          color: #999999;
          font-size: 12px;
          text-align: center;
        }
        .group-title {
          color: #333333;
          font-size: 14px;
        }
        .label {
          width: 33%;
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 12px;
          color: #999999;
          margin-bottom: 12px;
          text-align: center;
        }
        .value {
          width: 33%;
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 12px;
          color: #333333;
          text-align: center;
          line-height: 28px;
        }
        .longlabel {
          width: 34%;
          text-align: left;
        }
        .longvalue {
          width: 34%;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .add-btn {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 12px;
      text-align: left;
      color: #999999;
      cursor: pointer;
      line-height: 36px;
      width: 80px;
      text-align: center;
      &:hover {
        color: #3456ff;
      }
    }

    .el-icon-delete {
      cursor: pointer;
      &:hover {
        color: red;
      }
    }

    .submit-btn {
      display: flex;
      align-items: center;
      .submit-btn-item {
        width: 134px;
        height: 32px;
        border-radius: 2px;
        opacity: 1;
        background: #3456ff;
        cursor: pointer;
        text-align: center;
        line-height: 32px;
        &:nth-child(1) {
          color: #fff;
        }
        &:nth-child(2) {
          background-color: #fff;
        }
      }
    }
  }
}
</style>
