<template>
  <div class="scene-exp">
    <div class="container">
      <el-row :gutter="8">
        <el-col :span="4">
          <div class="box">
            <div class="box-label">场景名称</div>
            <div class="box-container" style="height:40px;">
              <div class="box-label-container">
                <p class="label"></p>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="box">
            <div class="box-label">设定条件</div>
            <div class="box-container" style="height:40px;">
              <div class="box-label-container">
                <p class="blankDiv"></p>
                <p class="label">选择设备</p>
                <p class="label">选择属性</p>
                <p class="label">选择条件</p>
                <p class="label">设定数值</p>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="box">
            <div class="box-label">触发动作</div>
            <div class="box-container" style="height:40px;">
              <div class="box-label-container">
                <p class="label longlabel">选择设备</p>
                <p class="label">工作状态</p>
                <p class="label">工作数值</p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row  :gutter="8" v-for="(item, index) in list" :key="index">
        <el-col :span="4">
          <div class="box">
            <div class="box-container">
              <div class="box-label-container">
                <p class="group-title">{{ item.name }}</p>
              </div>
              <div style="margin-top:10px;">
                <span @click="editItem(item)" style="margin-right:15px;color:blue;">编辑</span>
                <span @click="deleteItem(item)" style="color:blue;">删除</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="box">
            <div class="box-container">
              <div class="box-scroll">
                <div class="box-label-container" v-for="(condition, index) in item.conditions" :key="index">
                  <p class="blankDiv">
                    <span v-if="index >= 1">{{item.conditions.length > 1 ? "或" : ""}}</span>
                  </p>
                  <p class="value">{{ formatterDevice(condition.deviceId) }}</p>
                  <p class="value">{{ condition.name }}</p>
                  <p class="value">{{ formatterCompare(condition.cmp) }}</p>
                  <p class="value">{{ condition.value }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="box">
            <div class="box-container">
              <div class="box-scroll">
                <div class="box-label-container" v-for="(action, index) in item.actions" :key="index">
                  <p class="value longvalue">{{ formatterDevice(action.deviceId) }}</p>
                  <p class="value">{{ action.name }}</p>
                  <p class="value">{{ action.value }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import compareList from "../js/compare.js";
export default {
  name: "SceneDetail",
  data() {
    return {
      list: [],
      compareList,
      deviceList: [],
    };
  },
  computed: {
    ...mapState(["projectCurrentRowData"]),
  },
  mounted() {
    this.getDeviceList();
    this.getData();
  },
  methods: {
    // 设备列表
    getDeviceList() {
      this.request.deviceList({ project: this.projectCurrentRowData.projectCode }).then((res) => {
        this.deviceList = res.data || [];
      });
    },
    editItem(item) {
      this.$emit("edit", item);
    },
    deleteItem(item) {
      this.$confirm("此操作将删除场景：【" + item.name + "】, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        this.deleteFun(item)
      })
      .catch(() => {});
    },
    deleteFun(item) {
      this.request.deleteRule({ id: item.id }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getData();
        }
      });
    },
    getData() {
      this.request.listRule({
        project: this.projectCurrentRowData.projectCode,
      }).then((res) => {
        const resData = res.data || [];
        resData.forEach(item => {
          const conditions = item.conditions || [];
          conditions.forEach(el => {
            el["nameLabel"] = el.attr;
          })
          const actions = item.actions || [];
          actions.forEach(el => {
            el["nameLabel"] = el.attr;
          })
        })
        this.list = resData;
      });
    },
    formatterCompare(id) {
      const findCompare = this.compareList.find((item) => item.value == id);
      if (findCompare) {
        return findCompare.label;
      }
      return "";
    },
    formatterDevice(id) {
      const device = this.deviceList.find((item) => item.id == id);
      if (device) {
        return device.categoryName;
      }
      return id;
    }
  },
};
</script>

<style lang="scss" lang="scss">
.scene-exp {
  .exp-title {
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 16px;
    color: #424e66;
    margin-bottom: 18px;
    margin-top: 18px;
  }

  .container {
    margin: 18px 0;
  }

  .box-label {
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 12px;
    color: #666666;
    margin-bottom: 8px;
  }

  .box-container {
    background: #f5f5f7;
    box-sizing: border-box;
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    height: 100px;
    .box-scroll {
      height: 72px;
      overflow-y: auto;
      padding-right: 12px;
      box-sizing: border-box;
    }
    .box-label-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .blankDiv {
        width: 25px;
        color: #999999;
        font-size: 12px;
        text-align: center;
      }
      .group-title {
        color: #333333;
        font-size: 14px;
      }
      .label {
        width: 33%;
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 12px;
        color: #999999;
        margin-bottom: 12px;
        text-align: center;
      }
      .value {
        width: 33%;
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 12px;
        color: #333333;
        text-align: center;
        line-height: 28px;
      }
      .longlabel {
        width: 34%;
        text-align: left;
      }
      .longvalue {
        width: 34%;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
